<template>
  <section class="time-line2" v-if="approvalList.length > 0">
    <a-timeline>
      <template v-for="(item, index) in approvalList" :key="index">
        <a-timeline-item>
          <template #dot>
            <div class="time-avatar">
              <img class="avatar"
                :src="item.avatarId ? item.fullAvatar : require('@/assets/images/default-avatar.png')" />
            </div>
          </template>
          <section :class="{ 'time-sec2': true, 'time-trans': index }" v-if="showType(item)"
            :style="index + 1 != approvalList.length ? 'height: 117px;' : ''">
            <!-- <div class="time-avatar">
              <img
                class="avatar"
                :src="item.avatarId ? item.fullAvatar : require('@/assets/images/default-avatar.png')"
              />
            </div> -->
            <span class="time-name">{{ item.staffName }}</span>
            （<span class="time-action" :style="{ color: archiveStatusObj[item.state]?.color || '#52C41A' }"> {{
              auditResultText(item, index)
            }} </span>）
            <p class="time-create">{{ item.examineTime }}</p>
            <div class="reason" v-if="item.state === 3 || item.state === 4" :title="item.opinion ? item.opinion : null">
              {{ item.opinion || '' }}
            </div>
          </section>

          <div v-else class="fill-box">
            <div class="fill-item" v-for="(el, index) in item.children">
              <div class="time-avatar">
                <img class="avatar"
                  :src="el.avatarId ? el.fullAvatar : require('@/assets/images/default-avatar.png')" />
              </div>
              <div>
                <p>
                  <span class="time-name">{{ el.staffName }}</span>
                  （<span class="time-action" :style="{ color: archiveStatusObj[el.state]?.color || '#52C41A' }">
                    {{ auditResultText(el, index) }} </span>）
                </p>
                <p class="time-create">{{ el.examineTime || '' }}</p>
              </div>
            </div>
          </div>
        </a-timeline-item>
      </template>
    </a-timeline>
  </section>
</template>

<script>
import { approvalStatusObj, archiveStatusObj } from '@/utils/constData'
import { computed } from 'vue'
// import '@/style/approval-timeline.less'
export default {
  name: 'approval-time-line',
  props: {
    approvalList: {
      type: Array,
      default: () => [],
    },
    archivedLength: {
      default: 0,
    },
    examineStatus: {
      default: 10,
    },
    isPrint: {
      default: 1
    },
    // 是否是补盖类型 false 为非补盖
    isFill: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    console.log(props, '我是数据');
    const auditResultText = computed(() => (item, index) => {
      if (item.state !== null) {
        return archiveStatusObj[item.state]?.name || '--'
      } else {
        if (index === props.approvalList.length - 1 && props.examineStatus === 4) {
          return '完成归档申请'
        } else {
          return index === 0 ? (props.isPrint == 1 ? '发起归档申请' : '发起申请') : '发起归档申请'
        }
      }
    })
    const showType = computed(() => (item) => {
      if (item.type === 1) return true
      if (props.isFill === false) return true
      return false
    })
    return {
      showType,
      auditResultText,
      approvalStatusObj,
      archiveStatusObj,
    }
  },
}
</script>

<style lang="less" scoped>
.time-line2 {
  overflow-x: auto;
  margin-bottom: 24px;

  .line-block {
    display: flex;
    justify-content: flex-start;
  }

  &::-webkit-scrollbar {
    display: block;
    border-radius: 1px;
    background: #f5f5f5;
    height: 3px;
  }

  &::-webkit-scrollbar-thumb {
    height: 3px;
    background: #c6c6c6;
    border-radius: 1px;
  }

  .reason {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 14px;
    font-family: PingFangSC, PingFangSC-Regular;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
    box-sizing: border-box;
  }

  .time-sec2 {
    .time-avatar {
      width: 40px;
      height: 40px;
      object-fit: cover;
      margin-bottom: 16px;

      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin: 4px 0;
      }
    }

    .time-name {
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.65);
      text-align: center;
      // white-space: nowrap;
      // text-overflow: ellipsis;
      // overflow: hidden;
    }

    .time-action {
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.45);
    }
  }
  .time-create {
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: rgba(0, 0, 0, 0.45);
    }

  .fill-box {
    margin-left: -56px;
    padding-top: 4px;
    display: flex;
    flex-wrap: wrap;

    .fill-item {
      margin-right: 16px;
      margin-bottom: 16px;
      display: flex;

      p {
        padding-top: 4px;
        padding-left: 16px;
      }
    }
  }

  .approval-img {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    object-fit: cover;
    transform: translateY(5px);
    margin-right: 4px;
  }

  .time-avatar {
    width: 40px;
    height: 40px;
    object-fit: cover;

    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
}

:deep(.ant-timeline-item) {
  padding: 0;
}

:deep(.ant-timeline-item-content) {
  margin-left: 56px;
  top: 0;
}

:deep(.ant-timeline-item-tail) {
  border-left: 1px solid #c3161c;
  top: 48px;
  left: 20px;
  height: 69px;
}

.ant-timeline-item-last {
  padding-bottom: 0;

  >.ant-timeline-item-tail {
    border-left: none;
  }
}

:deep(.ant-timeline-item-head-custom) {
  padding: 0;
  transform: translate(0, 0);
  left: 0;
  top: 0;
  margin: 4px 0;
}
</style>
